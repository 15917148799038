import * as React from "react"
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import "../styles/global.css"

export default function Layout({children}) {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
					description
				}
			}
		}
	`)
	if (typeof window !== 'undefined') {
		let prevScrollpos = window.pageYOffset;
		window.onscroll = function () {
			const maxScroll = document.body.clientHeight - window.innerHeight;
			let currentScrollPos = window.pageYOffset;
			if (
				(maxScroll > 0 && prevScrollpos > currentScrollPos && prevScrollpos <= maxScroll)
				|| (maxScroll <= 0 && prevScrollpos > currentScrollPos)
				|| (prevScrollpos <= 0 && currentScrollPos <= 0)
			) {
				document.getElementById("navbar").style.top = "0";
			} else {
				document.getElementById("navbar").style.top = "-5.0rem";
			}
			prevScrollpos = currentScrollPos;
		}
	}
	return (
		<div className="layout animated fadeIn">
			<Helmet>
				<title>{data.site.siteMetadata.title}</title>
				<meta name="description" content={data.site.siteMetadata.description}/>
			</Helmet>
			<Navbar/>
			<div className="content">
				{children}
			</div>
			<Footer/>
		</div>
	)
}