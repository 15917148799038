import * as React from "react"

export default function Footer() {
	return (
		<footer className="footer text-center">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-md-8">
						<h2>Let's Connect</h2>
						<p className="email-message">Feel free to email me for work inquiries or just a friendly hello 😄<br/><a href="mailto:hxtasnim@gmail.com">hxtasnim@gmail.com</a></p>
						<p className="small copyright">2021 © Coded by Humaira Tasnim using GatsbyJS<br/></p>
					</div>
				</div>
			</div>
		</footer>
	)
}