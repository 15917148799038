import * as React from "react"
import { Link } from "gatsby"

export default function Navbar() {
	return (
		<nav className="navbar navbar-expand-lg fixed-top" id="navbar">
			<div className="container">
				<Link to="/" className="navbar-brand">Humaira Tasnim</Link>
				<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
					<span className="navbar-toggler-icon"/>
				</button>
				<div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
					<ul className="navbar-nav">
						<li className="nav-item">
							<Link to="/" className="nav-link" activeClassName="active">Work</Link>
						</li>
						<li className="nav-item">
							<Link to="/about" className="nav-link" activeClassName="active">About</Link>
						</li>
						<li className="nav-item">
							<a href="https://drive.google.com/file/d/1-wvxsmVsd9dtY8KqOMrX8MVYUoNXvE8C/view?usp=sharing" className="nav-link" target="_blank" rel="noreferrer">Résumé</a>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	)
}
